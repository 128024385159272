import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import Organization from "./pages/Organization";
import ForRespondents from "./pages/ForRespondents";
import ContactUs from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsUse from "./pages/TermsUse";
// import CookieConsent, { Cookies } from "react-cookie-consent";

function App() {
  // const [showCookieConsent, setShowCookieConsent] = useState(true);

  // const cookieModalClose = () => {
  //   setShowCookieConsent(false); // Hides the modal
  // };
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/organization" replace />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/for-respondents" element={<ForRespondents />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-use" element={<TermsUse />} />
        </Routes>
        <Footer />
      </Router>
      {/* {showCookieConsent && (
        <CookieConsent
          location="bottom"
          buttonText="Accept All Cookies"
          cookieName="mySurvHaNaCookie"
          style={{
            alignItems: "center",
            background: "#f6f6f6",
            color: "#000000",
            borderTop: "1px solid #d9d9d9",
          }}
          buttonStyle={{
            color: "#ffffff",
            fontSize: "16px",
            backgroundColor: "#5959be",
          }}
          expires={150}
          enableDeclineButton
          declineButtonText="Cookie Settings"
        >
          <p className="mb-0">
            Welcome to SurvHaNa! We use cookies to ensure you have the best
            experience on our site. Cookies help us analyze traffic, personalize
            content, and provide social media features.
          </p>
          <p className="mb-0">
            By clicking "Accept All Cookies," you agree to our use of cookies.
            You can manage your preferences or learn more by visiting our
            Privacy Policy.
          </p>
          <button
            onClick={cookieModalClose}
            className="btn btn-primary"
            style={{
              borderRadius: "50%",
              padding: "5px 10px",
              fontSize: "16px",
              position: "absolute",
              top: "-20px",
              right: "15px",
            }}
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </CookieConsent>
      )} */}
    </div>
  );
}

export default App;
