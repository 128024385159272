import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS } from "../constants/googleAnalytics";

export const GA = {
  dispatchGAEvent,
  initialize,
};

function dispatchGAEvent(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

function initialize() {
  ReactGA.initialize(GOOGLE_ANALYTICS.MEASURMENT_ID);
}
