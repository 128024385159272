import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="work-section-container d-block w-100 py-5">
        <Container>
          <div className="d-block w-100 text-center pb-5 heading">
            How It Works
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                01
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Tell Us About Yourself</h3>
                <p>
                  Answer a few questions to help us understand your interests,
                  preferences, and demographics. This ensures that we only send
                  you surveys that are relevant to you.
                </p>
              </div>
            </div>
            <div className="line"></div>
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                02
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Get Notified</h3>
                <p>
                  Once a survey that matches your profile is live, you'll
                  receive a notification on your mobile device. No need to
                  constantly check—just keep an eye on your notifications!
                </p>
              </div>
            </div>
            <div className="line last"></div>
          </div>
          <div className="mx-auto position-relative work-box">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                03
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Share Your Insights</h3>
                <p>
                  Complete the survey at your convenience. Each survey is
                  designed to be straightforward and engaging, allowing you to
                  share your thoughts with ease.
                </p>
              </div>
            </div>
            <div className="line"></div>
          </div>
          <div className="mx-auto position-relative work-box last">
            <div className="d-flex align-items-center w-100 px-3 py-4 box">
              <div className="d-inline-flex text-center justify-content-center number">
                04
              </div>
              <div className="d-inline-flex flex-wrap contents">
                <h3>Earn Rewards</h3>
                <p>
                  Your time and opinions are valuable. Every survey you complete
                  earns you points, which can be redeemed for exciting rewards.
                  The more surveys you participate in, the more you can earn!
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default HowItWorks;
