import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="contents-container d-block w-100 py-5">
        <div className="d-block w-100 pb-5 text-center heading">
          Privacy Policy
        </div>
        <Container>
          <p className="mb-2">Effective Date: September 30, 2024</p>
          <p className="mb-2">
            Welcome to SurvHaNa ("we," "our," or "us"). At SurvHaNa, we
            prioritize the privacy of our users and are committed to protecting
            the personal information you share with us. This Privacy Policy
            outlines how we collect, use, and safeguard your information when
            you use our website, platform, and mobile applications
            (collectively, the "Services").
          </p>
          <p className="mb-3">
            By using the Services, you agree to the collection and use of
            information in accordance with this policy. If you do not agree with
            any part of this Privacy Policy, please do not use our Services.
          </p>
          <p className="mb-1 fs-4">
            <strong>1. Information We Collect</strong>
          </p>
          <p className="mb-3">
            We collect several types of information from and about users,
            including:
          </p>
          <p className="mb-1">
            <b>1.1 Personal Data:</b>
          </p>
          <p>
            - Information you provide to us when you register or fill out
            surveys (e.g., name, email, gender, age, etc.).
          </p>
          <p className="mb-3">
            - Responses to surveys, including data points such as your income,
            family details, education background, and more.
          </p>
          <p>
            <b>1.2 Usage Data:</b>
          </p>
          <p className="mb-3">
            - Information automatically collected when you access or use our
            Services, such as your IP address, browser type, device type,
            operating system, and browsing activity.
          </p>
          <p>
            <b>1.3 Cookies and Tracking Technologies:</b>
          </p>
          <p className="mb-3">
            - We use cookies and similar tracking technologies to enhance user
            experience, analyze site performance, and customize content.
          </p>
          <p className="mb-1 fs-4">
            <strong>2. How We Use Your Information</strong>
          </p>
          <p className="mb-2">
            We use the collected information for the following purposes:
          </p>
          <p>- To provide and improve our Services.</p>
          <p>
            - To match surveys with respondents based on demographic criteria
            (e.g., age, gender, region).
          </p>
          <p>
            - To communicate with you about updates, services, or
            support-related inquiries.
          </p>
          <p>
            - To reward respondents for legitimate and accurate survey
            responses.
          </p>
          <p className="mb-3">
            - To comply with legal obligations and protect the rights and safety
            of our users and Services.
          </p>
          <p className="mb-1 fs-4">
            <strong>3. Sharing Your Information</strong>
          </p>
          <p>We may share your personal information in the following ways:</p>
          <p>
            - With Survey Organizations: When you participate in a survey, we
            may share anonymized or aggregated data with the organization
            commissioning the survey.
          </p>
          <p className="mb-3">
            - Legal Requirements: We may disclose your data if required by law,
            such as in response to a court order or other legal processes.
          </p>
          <p className="mb-1 fs-4">
            <strong>4. Data Security</strong>
          </p>
          <p className="mb-3">
            We take appropriate security measures to protect against
            unauthorized access, alteration, disclosure, or destruction of your
            personal data. However, no data transmission over the internet or
            electronic storage is 100% secure, and we cannot guarantee its
            absolute security.
          </p>
          <p className="mb-1 fs-4">
            <strong>5. Your Rights</strong>
          </p>
          <p className="mb-1">
            You may have the following rights regarding your personal data:
          </p>
          <p>
            - Access: You have the right to request access to the personal
            information we hold about you.
          </p>
          <p>
            - Correction: You can request correction of any inaccurate or
            incomplete information.
          </p>
          <p>
            - Deletion: You have the right to request the deletion of your data,
            subject to certain exceptions.
          </p>
          <p className="mb-2">
            - Opt-Out: You may opt-out of marketing communications at any time
            by following the unsubscribe instructions in those emails.
          </p>
          <p className="mb-3">
            To exercise these rights, please contact us at [email@example.com].
          </p>
          <p className="mb-1 fs-4">
            <strong>6. Third-Party Links</strong>
          </p>
          <p className="mb-3">
            Our Services may contain links to third-party websites. We are not
            responsible for the privacy practices or content of those
            third-party sites. We recommend reviewing their privacy policies
            before providing them with any personal information.
          </p>
          <p className="mb-1 fs-4">
            <strong>7. Children's Privacy</strong>
          </p>
          <p className="mb-3">
            Our Services are not directed to individuals under the age of 18,
            and we do not knowingly collect personal information from children.
            If you believe we have inadvertently collected such data, please
            contact us so we can take appropriate action.
          </p>
          <p className="mb-1 fs-4">
            <strong>8. Changes to This Privacy Policy</strong>
          </p>
          <p className="mb-3">
            We may update this Privacy Policy from time to time. When we make
            changes, we will notify you by updating the "Effective Date" at the
            top of this policy. We encourage you to review this policy
            periodically for any changes.
          </p>
          <p className="mb-1 fs-4">
            <strong>9. Contact Us</strong>
          </p>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p>
            - Email: [
            <a href="mailto:jasmin@survhana.com">jasmin@survhana.com</a>](
            <a href="mailto:contact@survhana.com">
              mailto:contact@survhana.com
            </a>
            )
          </p>
          <p>
            - Website: [<a href="https://www.survhana.com">www.survhana.com</a>
            ](
            <a href="https://www.survhana.com">https://www.survhana.com</a>)
          </p>
        </Container>
      </div>
    );
  }
}
export default PrivacyPolicy;
