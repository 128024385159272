import React from "react";
import { Row, Col, Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer d-block w-100 py-4">
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={12}>
            <Navbar>
              <Nav className="me-auto flex-wrap">
                <Link to="/" className="nav-link">
                  Home
                </Link>
                <Link to="/contact-us" className="nav-link">
                  Contact us
                </Link>
                <Link to="/privacy-policy" className="nav-link">
                  Privacy policy
                </Link>
                <Link to="/terms-use" className="nav-link">
                  Terms of Use
                </Link>
              </Nav>
            </Navbar>
          </Col>
          <Col lg={6} md={12}>
            <div className="d-block w-100 text-end copy-text">
              © SurvHaNa - Your Ultimate Survey Solution 2024
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
