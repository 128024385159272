import React, { Component } from "react";
import MainBanner from "./MainBanner";
import HowItWorks from "./HowItWorks";
import WhySurvey from "./WhySurvey";
import GetStartedNow from "./GetStartedNow";
import { GA } from "../../utils/ga";
import { GOOGLE_ANALYTICS } from "../../constants/googleAnalytics";

class ForRespondents extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    GA.dispatchGAEvent(
      GOOGLE_ANALYTICS.EVENT_CATEGORIES.WEBSITE,
      GOOGLE_ANALYTICS.EVENT_ACTIONS.RESPONDENTS_PAGE_LOADED
    );
  };

  render() {
    return (
      <div className="main-container d-block w-100">
        <MainBanner />
        <GetStartedNow />
        <HowItWorks />
        <WhySurvey />
      </div>
    );
  }
}

export default ForRespondents;
