import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

class TermsUse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="contents-container d-block w-100 py-5">
        <div className="d-block w-100 pb-5 text-center heading">
          Terms of Use
        </div>
        <Container>
          <p className="mb-3">Effective Date: September 30, 2024</p>
          <p className="mb-3">
            Welcome to SurvHaNa ("we," "our," or "us"). These Terms of Use
            ("Terms") govern your access and use of our website, platform,
            mobile application, and related services (collectively, the
            "Services"). By accessing or using our Services, you agree to comply
            with and be bound by these Terms. If you do not agree to these
            Terms, please do not use the Services.
          </p>
          <p className="mb-2 fs-4">
            <strong>1. Use of Services</strong>
          </p>
          <p className="mb-1">
            <b>1.1 Eligibility:</b>
          </p>
          <ul className="mb-3">
            <li>
              You must be at least 18 years of age to use our Services. By using
              the Services, you represent and warrant that you are at least 18
              years old and legally able to enter into these Terms.
            </li>
          </ul>
          <p className="mb-1">
            <b>1.2 Account Registration:</b>
          </p>
          <ul className="mb-3">
            <li>
              You may be required to create an account to access certain
              features of the Services. You agree to provide accurate, current,
              and complete information during registration and to keep your
              account information updated.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account.
            </li>
          </ul>
          <p className="mb-1">
            <b>1.3 Acceptable Use:</b>
          </p>
          <ul className="mb-3">
            <li>
              You agree to use the Services for lawful purposes only and in
              accordance with these Terms. You agree not to:
              <ol>
                <li>Violate any applicable laws or regulations.</li>
                <li>Submit false, misleading, or fraudulent information.</li>
                <li>
                  Use the Services to distribute viruses or other harmful
                  computer code.
                </li>
                <li>
                  Attempt to gain unauthorized access to any part of the
                  Services or related systems.
                </li>
              </ol>
            </li>
          </ul>
          <p className="mb-1">
            <b>1.4 Restrictions:</b>
          </p>
          <ul className="mb-3">
            <li>
              You may not use the Services to conduct any illegal activities or
              violate the rights of others. SurvHaNa reserves the right to
              suspend or terminate your account for any misuse or violation of
              these Terms.
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>2. Survey Participation and Data Collection</strong>
          </p>
          <p className="mb-1">
            <b>2.1 Survey Participation:</b>
          </p>
          <ul className="mb-3">
            <li>
              SurvHaNa offers a platform for companies to publish targeted
              surveys. By participating in these surveys, you agree to provide
              accurate and honest responses
            </li>
            <li>
              You acknowledge that your participation in any survey is
              voluntary, and you may opt-out of any survey at any time.
            </li>
          </ul>
          <p className="mb-1">
            <b>2.2 Data Collection and Privacy:</b>
          </p>
          <ul className="mb-3">
            <li>
              By using our Services, you agree to our collection, use, and
              processing of data as described in our Privacy Policy, including
              demographic information (e.g., gender, age, income, family
              details) for survey purposes.
            </li>
            <li>
              You agree that the data you provide may be shared with the
              organizations that commission surveys, as specified in our Privacy
              Policy.
            </li>
          </ul>
          <p className="mb-1">
            <b>2.3 Rewards:</b>
          </p>
          <ul className="mb-3">
            <li>
              Participants may receive rewards for completing surveys if their
              responses meet the criteria for legitimate and accurate data.
              Rewards will be distributed as per the terms outlined for each
              survey.
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>3. Intellectual Property</strong>
          </p>
          <p className="mb-1">
            <b>3.1 Ownership:</b>
          </p>
          <ul className="mb-3">
            <li>
              All content, features, and functionality of the Services,
              including but not limited to text, graphics, logos, images, and
              software, are the exclusive property of SurvHaNa or its licensors
              and are protected by international copyright, trademark, and other
              intellectual property laws.
            </li>
          </ul>
          <p className="mb-1">
            <b>3.2 Limited License:</b>
          </p>
          <ul className="mb-3">
            <li>
              SurvHaNa grants you a limited, non-exclusive, non-transferable
              license to access and use the Services for your personal,
              non-commercial use, subject to these Terms. Any unauthorized use
              of the Services may result in the termination of the license
              granted under these Terms.
            </li>
          </ul>
          <p className="mb-1">
            <b>3.3 Restrictions:</b>
          </p>
          <ul className="mb-3">
            <li>
              You may not copy, modify, distribute, sell, or lease any part of
              our Services or included software, nor may you reverse engineer or
              attempt to extract the source code of that software, unless laws
              prohibit these restrictions.
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>4. Termination of Use</strong>
          </p>
          <p className="mb-1">
            <b>4.1 Termination by You:</b>
          </p>
          <ul className="mb-3">
            <li>
              You may discontinue your use of the Services at any time by
              deleting your account or stopping the use of the Services.
            </li>
          </ul>
          <p className="mb-1">
            <b>4.2 Termination by SurvHaNa:</b>
          </p>
          <ul className="mb-3">
            <li>
              We reserve the right to suspend or terminate your access to the
              Services at any time, without notice, for any reason, including
              violation of these Terms or conduct that we believe is harmful to
              our users, us, or third parties.
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>5. Disclaimer of Warranties</strong>
          </p>
          <p className="mb-1">
            <b>5.1 As-Is Basis:</b>
          </p>
          <ul className="mb-3">
            <li>
              The Services are provided on an "as-is" and "as-available" basis,
              without any warranties of any kind, either express or implied,
              including but not limited to warranties of merchantability,
              fitness for a particular purpose, non-infringement, or
              availability of the Services.
            </li>
          </ul>
          <p className="mb-1">
            <b>5.2 No Guarantees:</b>
          </p>
          <ul className="mb-3">
            <li>
              SurvHaNa does not guarantee the accuracy, completeness, or
              usefulness of any information on the platform, nor that the
              Services will be uninterrupted, error-free, or secure.
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>6. Limitation of Liability</strong>
          </p>
          <p className="mb-1">
            <b>6.1 Exclusion of Certain Damages:</b>
          </p>
          <ul className="mb-3">
            <li>
              To the fullest extent permitted by law, SurvHaNa shall not be
              liable for any indirect, incidental, special, or consequential
              damages, including but not limited to lost profits, lost data, or
              business interruption arising out of or in connection with your
              use of or inability to use the Services.
            </li>
          </ul>
          <p className="mb-1">
            <b>6.2 Maximum Liability:</b>
          </p>
          <ul className="mb-3">
            <li>
              SurvHaNa's total liability to you for any claims arising out of or
              related to these Terms or the Services shall not exceed the amount
              you paid to SurvHaNa, if any, for the specific Services at issue
              during the twelve (12) months preceding the claim.
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>7. Governing Law and Dispute Resolution</strong>
          </p>
          <p className="mb-1">
            <b>7.1 Governing Law:</b>
          </p>
          <ul className="mb-3">
            <li>
              These Terms are governed by and construed in accordance with the
              laws of India, without regard to its conflict of law principles.
            </li>
          </ul>
          <p className="mb-1">
            <b>7.2 Dispute Resolution:</b>
          </p>
          <ul className="mb-3">
            <li>
              Any disputes arising under these Terms will be resolved through
              binding arbitration in accordance with the [applicable arbitration
              rules] of India. You agree to submit to the personal jurisdiction
              and venue of the courts located in [Insert Location].
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>8. Changes to the Terms</strong>
          </p>
          <p className="mb-1">
            <b>8.1 Modifications:</b>
          </p>
          <ul className="mb-3">
            <li>
              We may revise these Terms from time to time. Any changes will be
              posted on this page with an updated "Effective Date." Your
              continued use of the Services following the posting of revised
              Terms constitutes your acceptance of those changes.
            </li>
          </ul>
          <p className="mb-2 fs-4">
            <strong>9. Contact Information</strong>
          </p>
          <p className="mb-2">
            If you have any questions about these Terms, please contact us at:
          </p>
          <ul className="mb-3">
            <li>
              Email:{" "}
              <a href="mailto:jasmin@survhana.com">jasmin@survhana.com</a>
            </li>
            <li>
              Website: <a href="https://www.survhana.com">www.survhana.com</a>
            </li>
          </ul>
          <p>
            <b>
              By using the SurvHaNa platform, you acknowledge that you have
              read, understood, and agree to be bound by these Terms of Use.
            </b>
          </p>
        </Container>
      </div>
    );
  }
}
export default TermsUse;
