import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { env } from "../../EnvironmentConfig";
import ContactBanner from "../../assets/img/contact-banner.gif";
import { GA } from "../../utils/ga";
import { GOOGLE_ANALYTICS } from "../../constants/googleAnalytics";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      message: "",
      errors: {},
      submitSuccess: false,
      apiError: null, // Add apiError state
      loading: false,
    };
  }

  componentDidMount = () => {
    GA.dispatchGAEvent(
      GOOGLE_ANALYTICS.EVENT_CATEGORIES.WEBSITE,
      GOOGLE_ANALYTICS.EVENT_ACTIONS.CONTACT_US_LOADED
    );
  };

  validateForm = () => {
    const { name, email, mobile, message } = this.state;
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;

    if (!name) errors.name = "Name is required";
    if (!email || !emailRegex.test(email))
      errors.email = "Valid email is required";
    if (!mobile || !mobileRegex.test(mobile))
      errors.mobile = "Valid 10-digit mobile number is required";
    if (!message) errors.message = "Message is required";

    return errors;
  };

  handleSubmit = () => {
    const errors = this.validateForm();
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true, apiError: null }); // Reset apiError
      const apiUrl = `${env.baseURL}/admin/contactUs`;

      const { name, email, mobile, message } = this.state;

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, mobile, message }),
      })
        .then((response) => {
          if (response.ok) {
            GA.dispatchGAEvent(
              GOOGLE_ANALYTICS.EVENT_CATEGORIES.WEBSITE,
              GOOGLE_ANALYTICS.EVENT_ACTIONS.FORM_SUBMIT,
              `name=${name}`
            );

            this.setState({
              submitSuccess: true,
              errors: {},
              name: "",
              email: "",
              mobile: "",
              message: "",
              loading: false,
            });
          } else {
            // Set the error message when the API fails
            this.setState({
              apiError:
                "There was an issue submitting your message. Please try again.",
              loading: false,
            });
          }
        })
        .catch((error) => {
          // Handle network errors
          this.setState({
            apiError:
              "Failed to connect to the server. Please try again later.",
            loading: false,
          });
        });

      setTimeout(() => {
        this.setState({ submitSuccess: false });
      }, 1000);
    } else {
      this.setState({ errors, submitSuccess: false, loading: false });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      errors,
      submitSuccess,
      apiError,
      name,
      email,
      mobile,
      message,
      loading,
    } = this.state;

    return (
      <div className="contact-container d-block w-100 py-5">
        <div className="d-block w-100 pb-5 text-center heading">Contact Us</div>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="banner-image">
                <img src={ContactBanner} alt="" />
              </div>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="Name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={name}
                      placeholder="Enter name"
                      onChange={this.handleChange}
                    />
                    {errors.name && (
                      <Form.Text className="text-danger">
                        {errors.name}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-4" controlId="Mobile">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type="number"
                      name="mobile"
                      value={mobile}
                      placeholder="Enter mobile"
                      onChange={this.handleChange}
                    />
                    {errors.mobile && (
                      <Form.Text className="text-danger">
                        {errors.mobile}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-4" controlId="Email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Enter email"
                      onChange={this.handleChange}
                    />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        {errors.email}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-4" controlId="Message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      value={message}
                      rows={3}
                      placeholder="Enter your message"
                      onChange={this.handleChange}
                    />
                    {errors.message && (
                      <Form.Text className="text-danger">
                        {errors.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <div className="d-block w-100 text-left mt-3">
                    <button
                      className="btn btn-primary fs-4"
                      onClick={this.handleSubmit}
                      disabled={loading}
                    >
                      Submit
                      {loading ? (
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : null}
                    </button>
                  </div>
                  {submitSuccess && (
                    <div className="d-block w-100 text-center text-success mt-3">
                      Your message was successfully submitted
                    </div>
                  )}
                  {apiError && (
                    <div className="d-block w-100 text-center text-danger mt-3">
                      {apiError}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ContactUs;
