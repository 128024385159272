export const GOOGLE_ANALYTICS = {};

GOOGLE_ANALYTICS.MEASURMENT_ID = "G-FWBD9FVHJV";

GOOGLE_ANALYTICS.EVENT_ACTIONS = {
  ORGANIZATION_PAGE_LOADED: "Organization page loaded",
  RESPONDENTS_PAGE_LOADED: "Respondents page loaded",
  LOGIN_CLICKED: "Login clicked",
  CONTACT_US_LOADED: "Contact us loaded",
  FORM_SUBMIT: "Form submit",
};

GOOGLE_ANALYTICS.EVENT_CATEGORIES = {
  WEBSITE: "Website",
};
