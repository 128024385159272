import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainBannerImg from "../../../assets/img/main-banner.gif";
import playstoreImg from "../../../assets/img/PlayStore.png";
import appleImg from "../../../assets/img/AppStore.png";

class MainBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEmailClick = () => {
    window.location.href = "mailto:jasmin@survhana.com";
  };

  render() {
    return (
      <div className="main-banner-container d-block w-100 pt-1 pb-5">
        <Container>
          <Row>
            <Col md={6} className="order-md-1">
              <div className="d-flex align-items-center w-100 banner-image">
                <img src={MainBannerImg} alt="" />
              </div>
            </Col>
            <Col md={6} className="order-md-0">
              <div className="d-flex w-100 h-100 align-items-center flex-wrap banner-contents">
                <div className="d-flex w-100 flex-wrap">
                  <h2 className="d-flex w-100">
                    Join SurvHaNa: Your Voice, Your Rewards
                  </h2>
                  <p className="d-block w-100">
                    <b>SurvHaNa</b> gives you the opportunity to share your
                    opinions and insights on topics that matter—and get rewarded
                    for it!
                  </p>
                  {/* <button
                    className="btn btn-primary"
                    onClick={this.handleEmailClick}
                  >
                    Join Now
                  </button> */}
                  <div className="d-block w-100 images">
                    <a
                      href="https://www.survhana.com/contact-us"
                      className="btn btn-primary"
                    >
                      Register
                    </a>
                    {/* <a
                      href="https://play.google.com/store/apps/details?id=com.survey.mobile.survhana"
                      target={"_blank"}
                      className="d-inline-block me-3"
                    >
                      <img src={playstoreImg} alt="Google Playstore" />
                    </a>
                    <a href="#" target={"_blank"} className="d-inline-block">
                      <img src={appleImg} alt="Apple" />
                    </a> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default MainBanner;
